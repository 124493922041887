/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {Link} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1, Search} from '../../../partials'


//Data Fsn
import {addDays, subDays, parseISO} from 'date-fns'

//getLocaleConfig
import {dateFormat} from '../../../../app/i18n/Metronici18n'

export function Tab1Menu() {
  const intl = useIntl()

  return (
    <>
      <Link to='/dashboard' className='d-flex align-items-center mb-4'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/baselLogoHeader.svg')}
          className='h-40px'
        />
      </Link>
      <div className='d-flex mb-10'>
        <Search />
        {/* begin::Filter */}
        <div className='flex-shrink-0 ms-2'>
          {/* begin::Menu toggle */}
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
          >
            <KTIcon iconName='filter' className='fs-2' />
          </button>
          {/* end::Menu toggle */}

          <Dropdown1 />
        </div>
        {/* end::Filter */}
      </div>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'TAB1.PAGES'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/dashboard'
        icon='profile-circle'
        title={intl.formatMessage({id: 'TAB1.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/calendar-and-reservation'
        icon='calendar-tick'
        title={intl.formatMessage({id: 'TAB1.CALENDAR_AND_RESERVATION'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/customer-management'
        icon='user-edit'
        title={intl.formatMessage({id: 'TAB1.CUSTOMER_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItem
        to='/staff-management'
        icon='profile-user'
        title={intl.formatMessage({id: 'TAB1.STAFF_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        to='/schedule-lectures'
        icon='notepad-bookmark'
        title={intl.formatMessage({id: 'TAB1.SCHEDULE_LECTURES'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/schedule-lectures/memberships'
          title={intl.formatMessage({id: 'TAB1.SCHEDULE_LECTURES.MEMBERSHIPS'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/schedule-lectures/lectures'
          title={intl.formatMessage({id: 'TAB1.SCHEDULE_LECTURES.LECTURES'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/financial-management'
        icon='calculator'
        title={intl.formatMessage({id: 'TAB1.FINANCIAL_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/financial-management/safe'
          title={intl.formatMessage({id: 'TAB1.FINANCIAL_MANAGEMENT_SAFE'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/financial-management/poses'
          title={intl.formatMessage({id: 'TAB1.FINANCIAL_MANAGEMENT_POSES'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/'
        icon='office-bag'
        title={intl.formatMessage({id: 'TAB1.NUTRITION_MANAGEMENT'})}
        fontIcon='bi-app-indicator'
        isDisable={true}
        isSoon={true}
        
      />

      <AsideMenuItemWithSub
        to='/reports'
        icon='chart-pie-3'
        title={intl.formatMessage({id: 'TAB1.REPORT'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/reports/memberships-detail?startDate=&end='
          title={intl.formatMessage({id: 'TAB1.REPORT_MEMBERSHIPS_DETAIL'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to={`/reports/memberships-ends?startDate=${dateFormat(subDays(new Date(), 30), 'yyyy-MM-dd')}&end=${dateFormat(new Date(), 'yyyy-MM-dd')}`}
          title={intl.formatMessage({id: 'TAB1.REPORT_MEMBERSHIPS_ENDS'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to={`/reports/memberships-starts?startDate=${dateFormat(new Date(), 'yyyy-MM-dd')}&end=${dateFormat(addDays(new Date(), 30), 'yyyy-MM-dd')}`}
          title={intl.formatMessage({id: 'TAB1.REPORT_MEMBERSHIPS_STARTS'})}
          hasBullet={true}
        />

        <AsideMenuItem
          to='/reports/package-activity-trasfer'
          title={intl.formatMessage({id: 'TAB1.REPORT_PACKAGE_ACTIVTY_TRASFER'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/package-activity-stopped'
          title={intl.formatMessage({id: 'TAB1.REPORT_PACKAGE_ACTIVTY_STOPPED'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/package-activity-cancelled'
          title={intl.formatMessage({id: 'TAB1.REPORT_PACKAGE_ACTIVTY_CANCELLED'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/notifications'
          title={intl.formatMessage({id: 'TAB1.NOTIFICATIONS'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/reports/bulk-credit'
          title={intl.formatMessage({id: 'TAB1.REPORT_BULK_CREDIT'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/facility-room-and-gates'
        icon='courier-express'
        title={intl.formatMessage({id: 'TAB1.FACILITY_ROOM_AND_GATES'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/facility-room-and-gates/facilities'
          title={intl.formatMessage({id: 'TAB1.FACILITY_ROOM_AND_GATES_FACILITIES'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/facility-room-and-gates/rooms'
          title={intl.formatMessage({id: 'TAB1.FACILITY_ROOM_AND_GATES_ROOMS'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/facility-room-and-gates/gates'
          title={intl.formatMessage({id: 'TAB1.FACILITY_ROOM_AND_GATES_GATES'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/general-settings'
        icon='gear'
        title={intl.formatMessage({id: 'TAB1.GENERAL_SETTINGS'})}
        fontIcon='bi-app-indicator'
      >
        <AsideMenuItem
          to='/general-settings/company-information'
          title={intl.formatMessage({id: 'TAB1.GENERAL_SETTINGS_COMPANY_INFORMATION'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/general-settings/poses'
          title={intl.formatMessage({id: 'TAB1.GENERAL_SETTINGS_POSES'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/general-settings/contracts'
          title={intl.formatMessage({id: 'TAB1.GENERAL_SETTINGS_CONTRACTS'})}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/general-settings/coupons'
          title={intl.formatMessage({id: 'TAB1.GENERAL_SETTINGS_COUPONS'})}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-11'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='shield-tick'
        title='User management'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}
